import type { ExtraContext } from "@segment/analytics-node/src/app/types/params"

export const enableTracking =
  process.env.NEXT_PUBLIC_DEBUG_TRACKING === "true" || process.env.NEXT_PUBLIC_SITE_URL === "https://workmaps.com"

export interface UTMs {
  utm_source?: string
  utm_medium?: string
  utm_campaign?: string
  utm_term?: string
  utm_content?: string
}

export function getUTMsFromURL(url: string): UTMs | undefined {
  const params = new URL(url).searchParams
  const utms: UTMs = {}

  if (params.has("utm_source")) {
    utms.utm_source = params.get("utm_source")!
  }

  if (params.has("utm_medium")) {
    utms.utm_medium = params.get("utm_medium")!
  }

  if (params.has("utm_campaign")) {
    utms.utm_campaign = params.get("utm_campaign")!
  }

  if (params.has("utm_term")) {
    utms.utm_term = params.get("utm_term")!
  }

  if (params.has("utm_content")) {
    utms.utm_content = params.get("utm_content")!
  }

  if (Object.values(utms).some((value) => value !== undefined)) {
    return utms
  }

  return undefined
}

export interface GoogleAdsQueryParameters {
  /**
   * Google Click Identifier - Unique identifier for tracking clicks on Google Ads.
   */
  gclid: string

  /**
   * Google Display Click Identifier - Used for tracking clicks on Google Display Network ads.
   */
  dclid?: string

  /**
   * Google Click Source - Identifies the source of the click (e.g., gclid for Google Ads, search for organic search).
   */
  gclsrc?: string

  /**
   * Google Ads Conversion ID - Used for tracking conversions back to Google Ads campaigns.
   */
  gac?: string

  /**
   * Google Click ID for Accelerated Mobile Pages (AMP) landing pages.
   */
  gclaw?: string
}

export function getGoogleAdsParamsFromURL(url: string): GoogleAdsQueryParameters | undefined {
  const searchParams = new URL(url).searchParams
  const googleAdsParams: Partial<GoogleAdsQueryParameters> = {}

  if (searchParams.has("gclid")) {
    googleAdsParams.gclid = searchParams.get("gclid")!
  }

  if (searchParams.has("dclid")) {
    googleAdsParams.dclid = searchParams.get("dclid")!
  }

  if (searchParams.has("gclsrc")) {
    googleAdsParams.gclsrc = searchParams.get("gclsrc")!
  }

  if (searchParams.has("gac")) {
    googleAdsParams.gac = searchParams.get("gac")!
  }

  if (searchParams.has("gclaw")) {
    googleAdsParams.gclaw = searchParams.get("gclaw")!
  }

  // For Segment's Google Ads Conversions destination, we must have the gclid to
  // do any tracking.
  if ("gclid" in googleAdsParams && Object.values(googleAdsParams).some((value) => !!value)) {
    return googleAdsParams as GoogleAdsQueryParameters
  }

  return undefined
}

export function cookiesUtmsToSegmentCampaign(utms: Record<string, string>): ExtraContext["campaign"] {
  if (!utms.utm_campaign && !utms.utm_source && !utms.utm_medium) {
    return undefined
  }

  return {
    name: utms.utm_campaign,
    source: utms.utm_source,
    medium: utms.utm_medium,
    term: utms.utm_term,
    content: utms.utm_content,
  }
}

export function getMicrosoftClickIdFromURL(url: string) {
  const searchParams = new URL(url).searchParams
  return searchParams.get("msclkid") ?? undefined
}

export function getTaboolaClickIdFromURL(url: string) {
  const searchParams = new URL(url).searchParams
  return searchParams.get("tbclid") ?? undefined
}

// Regular expression that matches ff_experiment_name[configKey]
const configQueryParamRe = /ff_([^[]+)(?:\[(\w+)\])?/

export function parseStatsigConfigFromQueryParam(
  key: string,
  value: null | string
): [string, string, string | number | boolean] | null {
  const keyMatch = configQueryParamRe.exec(key)

  if (!keyMatch || keyMatch.length !== 3) return null

  const experimentName = keyMatch[1]
  const configKey = keyMatch[2] ?? "is_enabled"
  let parsedValue = !value ? true : value

  if (parsedValue === "true" || parsedValue === "false") {
    parsedValue = parsedValue === "true"
  }

  return [experimentName, configKey, parsedValue]
}

const gateQueryParamRe = /ff_([^[]+)?/

export function parseStatsigGateFromQueryParam(key: string, value: null | string): [string, boolean] | null {
  const keyMatch = gateQueryParamRe.exec(key)

  if (!keyMatch || keyMatch.length !== 2) return null

  const experimentName = keyMatch[1]
  const parsedValue = value === "true" || value === "enabled" || !value

  return [experimentName, parsedValue]
}
