import Cookies from "js-cookie"
import { StatsigClient, type StatsigOptions, type StatsigUser } from "@statsig/js-client"
import { StatsigProvider as RealStatsigProvider } from "@statsig/react-bindings"
import { useEffect, useMemo } from "react"
import { useSearchParams } from "next/navigation"
import { useUser } from "./UserContext"
import { useIsMedium } from "@/lib/frontend/util"
import { parseStatsigGateFromQueryParam, parseStatsigConfigFromQueryParam } from "@/lib/shared/tracking"

const loggedOverrides = new Set<string>()

export type StatsigProviderProps = {
  statsigValues: string
  statsigUser: StatsigUser
}

export const StatsigProvider: React.FC<React.PropsWithChildren<StatsigProviderProps>> = ({
  children,
  statsigValues,
  statsigUser,
}) => {
  const user = useUser()
  const userID = statsigUser.userID ?? user?.id ?? Cookies.get("ajs_user_id")
  const email = statsigUser.email ?? user?.email
  const searchParams = useSearchParams()!
  const isMedium = useIsMedium()
  const viewport: undefined | "mobile" | "desktop" =
    isMedium !== undefined ? (isMedium ? "mobile" : "desktop") : undefined

  // In order to allow easy sharing of in flight features, we have a query parameter convention to control
  // experiment/layer/gate values. These changes are persisted in cookies so that the URL can be cleared out
  // during development.
  const overrideAdapter = useMemo<NonNullable<StatsigOptions["overrideAdapter"]>>(() => {
    if (searchParams.has("ff_clear_all")) {
      Cookies.remove("statsig_overrides")
    }

    const cachedOverrides: Record<string, Record<string, string | number | boolean>> = JSON.parse(
      Cookies.get("statsig_overrides") ?? "{}"
    )
    const overrides: typeof cachedOverrides = {}

    for (const [key, value] of searchParams.entries()) {
      if (!key.startsWith("ff_")) continue
      const config = parseStatsigConfigFromQueryParam(key, value)
      if (!config) continue
      const [ffName, configKey, overrideValue] = config
      if (!overrides[ffName]) overrides[ffName] = {}
      overrides[ffName][configKey] = overrideValue
    }

    const gateOverrides: Record<string, boolean> = {}

    for (const [key, value] of searchParams.entries()) {
      if (!key.startsWith("ff_gate_")) continue
      const gate = parseStatsigGateFromQueryParam(key, value)
      if (!gate) continue
      const [ffName, overrideValue] = gate
      gateOverrides[ffName] = overrideValue
    }

    return {
      getLayerOverride: (current, _user, _options) => {
        const updated = { ...current }
        const override = { ...(overrides?.[current.name] ?? {}), ...(cachedOverrides?.[current.name] ?? {}) }
        if (Object.keys(override).length > 0) {
          updated.__value = { ...current.__value, ...override }
          updated.details = { ...current.details, reason: "LocalOverride" }
          if (!loggedOverrides.has(current.name)) {
            /* eslint-disable-next-line no-console */
            console.log(`Statsig: Overriding config for ${updated.name}`, updated.__value)
            loggedOverrides.add(current.name)
          }
          Cookies.set(
            "statsig_overrides",
            JSON.stringify({
              ...cachedOverrides,
              [current.name]: override,
            })
          )
        }
        return updated
      },
      getExperimentOverride: (current, _user, _options) => {
        const updated = { ...current }
        const override = { ...(overrides?.[current.name] ?? {}), ...(cachedOverrides?.[current.name] ?? {}) }
        if (Object.keys(override).length > 0) {
          updated.value = { ...current.value, ...override }
          updated.details = { ...current.details, reason: "LocalOverride" }
          if (!loggedOverrides.has(current.name)) {
            /* eslint-disable-next-line no-console */
            console.log(`Statsig: Overriding config for ${updated.name}`, updated.value)
            loggedOverrides.add(current.name)
          }
        }
        Cookies.set(
          "statsig_overrides",
          JSON.stringify({
            ...cachedOverrides,
            [current.name]: override,
          })
        )
        return updated
      },
      getGateOverride: (current, _user, _options) => {
        const updated = { ...current }
        const override = gateOverrides[current.name]

        if (typeof override === "boolean") {
          updated.value = override
          if (!loggedOverrides.has(current.name)) {
            /* eslint-disable-next-line no-console */
            console.log(`Statsig: Overriding gate for ${updated.name}`, updated.value)
            loggedOverrides.add(current.name)
          }
        }

        return updated
      },
    }
  }, [searchParams])

  const statsigClient = useMemo(() => {
    const statsigClient = new StatsigClient(process.env.NEXT_PUBLIC_STATSIG_CLIENT_API_KEY, statsigUser, {
      overrideAdapter,
      environment: {
        tier: process.env.NEXT_PUBLIC_STATSIG_ENVIRONMENT ?? "development",
      },
    })
    statsigClient.dataAdapter.setData(statsigValues)
    statsigClient.initializeSync()
    return statsigClient
  }, [overrideAdapter, statsigValues, statsigUser])

  useEffect(() => {
    const updatedUser: StatsigUser = {
      ...statsigUser,
      userID,
      email: email ?? statsigUser.email,
      custom: {
        ...(statsigUser.custom ?? {}),
        viewport,
      },
    }

    statsigClient.updateUserSync(updatedUser)
  }, [userID, email, statsigUser, viewport, statsigClient])

  return <RealStatsigProvider client={statsigClient}>{children}</RealStatsigProvider>
}
